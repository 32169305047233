import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Thème du défi : Le syndrome de la page blanche`}</em></p>
    <p>{`Par une belle nuit d’été, il était face à son écran les doigts figé alors que l’ambiance chaleureuse de la rue embaumait sa chambre de par sa fenêtre ouverte. Il contemplait passivement son ordinateur depuis quelques minutes sans ne savoir qu’écrire. Ses doigts frétillaient de tension mais l’inspiration ne venait pas. La fenêtre de conversation avec Fanny n’avait jamais semblé aussi figée, aborder la fille qu’il aime sur un réseau social n’était vraiment pas son point fort.`}</p>
    <p>{`« Salut » écrit-il timidement. Chaque pression sur le clavier frappait son coeur et le rythme des battements était devenu intense. Il dut faire une pause à rêvasser, le regard vers la fenêtre. Les rues étaient actives l’été, ce qui ne le déplaisait pas. Il avait vu Fanny pour la dernière fois, tout juste la veille où, ivre et épanoui, leur discussion avait occupé une bonne partie de la soirée. Le rire sur son visage avait illuminé la nuit et il pourrait passer son existence à parler de tous les sujets du monde en admirant ses manières.`}</p>
    <p>{`Le mot « Salut » était toujours là, inerte, il n’avait pas encore osé l’envoyer. Il fallait plus de consistence. Essayer d’être drôle encore une fois ? Son navigateur lui montrait la photo de profil de la jeune fille, qu’il avait toujours trouvé peu flatteuse sans jamais avoir osé lui dire, de peur de dévoiler ses sentiments.`}</p>
    <p>{`Il ferma les yeux quelques minutes essayant de s’imaginer Fanny, son visage rieur, ses cheveux brillants, et ses yeux pétillants. Comment croire qu’elle puisse s’intéresser à lui ? Le mot « Salut » était toujours figé entouré de néant dans sa fenêtre grande ouverte. Il cliqua sur son profil. Elle venait de poster. Une photo d’elle dans un parc. Déjà 10 likes dont 5 garçons. Il cliqua le bouton Like pour rejoindre une masse où rien ne le démarquait.`}</p>
    <p>{`Plus loin dans son profil, elle avait déjà beaucoup de contributions. Plusieurs commentaires venant de garçons, elle y répondait de façon chaleureuse avec l’humour et la générosité qui la caractérisait. Rien ne le plaçait au dessus de tous ces prétendants. Il ouvrait leurs profils. Il cliquait. La comparaison n’était pas à son avantage. Toutes ces personnes rayonnaient de notoriété. Il naviguait à travers les profils. La liste des amis. Les photos. Les commentaires. Tous avaient un flot ininterrompu de prestige alors que lui n’arrivait pas à écrire un simple message. Un rire le surprit. La fenêtre était encore ouverte et les foules de passants s’égosillaient dans une ivresse commune pendant que lui, seul face à son écran, ne savait pas comment séduire la fille qu’il aimait.`}</p>
    <p>{`Le mot « Salut » était toujours planté en plein milieu de sa fenêtre ouverte, rayonnant de désolation et symbole de son inaptitude à plaire. Au dessus de ce message plein de vacuité siégeait l’historique des conversations qu’il avait eu avec Fanny mais cela ne faisait que souligner l’étendue de son insignifiance auprès d’elle. Peut-être ne se souviendrait-elle pas de lui et ne lui montrerait que le peu d’estime qu’elle a. Il n’avait que trop vécu cela. Le rejet le détruirait comme d’habitude. Il ne voulait plus souffrir. Un risque vaut-il le coup d’être pris quand il peut causer la perte de tout espoir ? Il veut continuer à rêver et à croire qu’il peut compter pour la personne qu’il aime. Ce message était une erreur et les battements de son coeur ne demandaient qu’à mettre fin à la situation au plus vite.`}</p>
    <p>{`Il observait les gens s’amuser dehors avec bienveillance. Cela débordait de joie l’autre côté de la vitre. Un courant d’air glaça ses doigts alors qu’il effaçait son message et ferma la fenêtre.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      